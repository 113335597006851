import React from "react";
import styled from "styled-components";
import { Row } from "react-bootstrap";
import { useAvailableActions } from "hooks/useAvailableActions";
import SupportedFieldsTable from "components/docs/integrations/SupportedFieldsTable";
import { APICategory } from "@merge-api/merge-javascript-shared";
import { Integration } from "./IntegrationsSandboxPage";
import { OpenAPIV3 } from "openapi-types";

interface SupportedFieldsPageProps {
  pageContext: {
    key: APICategory;
    integrations: {
      integration: Integration;
    };
    categorySchemas: Record<APICategory, OpenAPIV3.Document>;
  };
}

const SupportedFieldsPage = ({ pageContext }: SupportedFieldsPageProps) => {
  const { key: categoryName, integrations, categorySchemas } = pageContext;

  const allAvailableActions = useAvailableActions();

  const allAvailableActionsForCategory = allAvailableActions[categoryName];

  return (
    <div className="w-full flex flex-col md:px-10 md:py-10 m-auto sm:px-1 sm:py-10">
      <div className="text-[26px] leading-[36px] font-semibold text-black mb-[18px]">
        Supported Integrations Fields
      </div>
      <div className="mb-9">
        See which fields are supported for each integration and compare coverage across platforms.
        This table only shows support for reading data.
      </div>
      <SupportedFieldsTable
        availableActions={allAvailableActionsForCategory}
        categoryName={categoryName}
        integrations={integrations}
        categorySchema={categorySchemas[categoryName]}
      />
    </div>
  );
};

export default SupportedFieldsPage;
